<template>
  <v-dialog v-model="dialog" persistent max-width="50%">
    <v-form refs="form" class="multi-col-validation">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">REFUND FORM</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" class="multi-col-validation mt-6">
            <v-col md="4" cols="12"></v-col>
            <v-row>
              <v-col md="4" cols="12">
                <v-text-field
                  v-model="fsc_no"
                  label="FSC No."
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col md="4" cols="12">
                <v-text-field
                  v-model="balance"
                  label="Balance"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col md="4" cols="12">
                <v-text-field
                  v-model="name"
                  label="Name"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col md="4" cols="12">
                <v-text-field
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                  v-model="fund_from"
                  label="Fund From"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col md="4" cols="12">
                <v-text-field
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                  v-model="claimant"
                  label="Claimant"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col md="4" cols="12">
                <v-text-field
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                  v-model="voucher_no"
                  label="Voucher #"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col md="4" cols="12">
                <v-text-field
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                  v-model="check_no"
                  label="Check #"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col md="4" cols="12">
                <v-text-field
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                  v-model="release_date"
                  label="Released Date"
                  dense
                  outlined
                  type="date"
                ></v-text-field>
              </v-col>
              <v-col md="4" cols="12">
                <v-text-field
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                  v-model="amount"
                  label="Amount"
                  dense
                  outlined
                  type="number"
                  min="0"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" class="mx-1" @click="save_details" v-if="!saving"> Save
            changes
          </v-btn>
          <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                               v-else></v-progress-circular>
          <v-btn class="mx-1" color="error" @click="close_dialog()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";

  const initialState = () => {
    return {
      saving: false,
      alert: false,
      alert_message: '',


      fund_from: '',
      claimant: '',
      voucher_no: '',
      check_no: '',
      release_date: '',
      amount: '',
    }
  }
  export default {
    name: "refunding",
    props: {
      dialog: Boolean,
      deceased_client_id: Number,
      fsc_no: String,
      balance: String,
      name: String,
    },
    data() {
      return initialState()
      this.close_dialog()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', ['branch_id', 'month_start', 'month_end', 'user_id']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('deceased_client_refund', ['save_deceased_client_refund']),
      save_details() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('branch_id', this.branch_id)
          data.append('deceased_client_id', this.deceased_client_id)
          data.append('claimant', this.claimant.toUpperCase())
          data.append('fund_from', this.fund_from.toUpperCase())
          data.append('voucher_no', this.voucher_no)
          data.append('check_no', this.check_no)
          data.append('date_of_released', moment(this.release_date, 'YYYY-MM-DD').format('MMMM D, YYYY'))
          data.append('amount', this.amount)
          this.save_deceased_client_refund(data)
            .then(response => {
              this.change_snackbar({
                show: true,
                color: 'success',
                text: response.data,
              })
              this.reset()
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
      close_dialog() {
        this.$emit('dialog', false)
      }
    }
  }
</script>

<style scoped>

</style>
