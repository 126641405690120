<template>
  <div>
    <v-card flat class="pa-3 mt-2">
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">LIST OF DECEASED WITH REFUND</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="mt-6">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-uppercase">FSC#</th>
              <th class="text-center text-uppercase">Name</th>
              <th class="text-center text-uppercase">Mode of Service</th>
              <th class="text-center text-uppercase">Balance</th>
              <th class="text-center text-uppercase">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in list_of_deceased_items" :key="item.id">
              <td>{{ item.fsc_no }}</td>
              <td class="text-center">
                {{ item.name}}
              </td>
              <td class="text-center">
                {{ item.mode_of_service}}
              </td>
              <td class="text-center">
                {{ formatPrice(item.balance)}}
              </td>
              <td class="text-center">
                <div>
                  <v-icon
                    v-if="!dialog_show"
                    class="mr-2"
                    color="success"
                    @click="on_refund(list_of_deceased_items[list_of_deceased_items.map(function(x) {return x.id; }).indexOf(item.id)])"
                  >
                    {{icons.mdiCheck}}
                  </v-icon>
                  <v-progress-circular color="info" indeterminate
                                       v-else></v-progress-circular>
                </div>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>


    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
    <refunding
      v-on:dialog="close_dialog"
      :dialog="dialog_show"
      :deceased_client_id="deceased_client_id"
      :name="name"
      :fsc_no="fsc_no"
      :balance="balance"
    ></refunding>
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline, mdiCheck} from '@mdi/js'
  import {mapGetters, mapActions} from 'vuex'
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import Refunding from './Refunding'

  const initialState = () => {
    return {
      list_of_deceased_items: [],

      dialog_show: false,

      deceased_client_id: 0,
      fsc_no: '',
      balance: '',
      name: '',
    }
  }
  export default {
    components: {
      snackBarDialog,
      Refunding,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiCheck,
        },
      }
    },
    created() {
      this.initializeData()
    },
    data() {
      return initialState()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', ['branch_id', 'month_start', 'month_end', 'user_id']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('transaction_months_history', ['get_transaction_month_history']),
      ...mapActions('deceased_client_refund', ['get_list_of_clients_refund', 'save_deceased_client_refund']),
      reset() {
        Object.assign(this.$data, initialState())
        this.initializeData()
      },
      initializeData() {
        const data = new FormData()
        data.append('branch_id', this.branch_id)
        this.get_list_of_clients_refund(data)
          .then(response => {
            this.list_of_deceased_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      on_refund(data) {
        this.dialog_show = true
        this.deceased_client_id = data.id
        this.fsc_no = data.fsc_no
        this.balance = this.formatPrice(data.balance)
        this.name = data.name
      },
      close_dialog(value) {
        this.dialog_show = value
      },

    },
  }
</script>
